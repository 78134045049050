<template>
  <v-container fluid>
      <v-row justify="center">
          <v-col cols="2">
            <v-subheader>
              Выберите год
            </v-subheader>
          </v-col>
          <v-col cols="1">
              <v-select v-model="selectedYear"
                        :items="years"
                        item-text="text"
                        item-value="value"
                        label="Год"
                        persistent-hint
                        return-object
                        single-line
                        class="mb-5">
              </v-select>
          </v-col>
          <v-col cols="2">
              <v-btn class="mr-5 mt-3" v-on:click="fetchPlan">
                  Показать план
                  <v-icon class="ml-3">mdi-file-table-outline</v-icon>
              </v-btn>
          </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
              <v-btn-toggle v-model="buttonToggle">
                  <v-btn @click="exportTableToXLSX">
                      Выгрузить план в файл
                      <v-icon class="ml-3">mdi-microsoft-excel</v-icon>
                  </v-btn>
              </v-btn-toggle>
          </v-col>
      </v-row>
      <KPIChart :title="plan.name"
                type="line"
                direction="shipment"
                :rows="plan.indicators" />
      <v-data-table :headers="options.headers"
                    :items="plan.indicators"
                    :loading="loading"
                    :search="search"
                    :items-per-page="itemsPerPage"
                    class="elevation-1"
                    :footer-props="{
      itemsPerPageAllText: 'Все',
      itemsPerPageText: 'Строк на страницу'
    }">
          <template v-slot:item.deviation="{ item }">
              <td v-if="item.deviation > 0" style="color: green">
                  {{ item.deviation }}
                  <v-icon class="ml-1" style="color: green">
                      mdi-arrow-up-thin
                  </v-icon>
              </td>
              <td v-else style="color: red">
                  {{ item.deviation }}
                  <v-icon class="ml-1" style="color: red">
                      mdi-arrow-down-thin
                  </v-icon>
              </td>
          </template>
          <template v-slot:item.weightDeviation="{ item }">
              <td v-if="item.weightDeviation > 0" style="color: green">
                  {{ item.weightDeviation }}
                  <v-icon class="ml-1" style="color: green">
                      mdi-arrow-up-thin
                  </v-icon>
              </td>
              <td v-else style="color: red">
                  {{ item.weightDeviation }}
                  <v-icon class="ml-1" style="color: red">
                      mdi-arrow-down-thin
                  </v-icon>
              </td>
          </template>
      </v-data-table>
      <v-snackbar v-model="snackbar" :top="true" :timeout="-1">
          <v-card>
              <v-card-title>
                  <span class="headline">{{ enrich.status }}</span>
              </v-card-title>
              <v-card-text>
                  {{ enrich.errorMessage }}
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn dark text @click="snackbar = false">
                      {{ enrich.buttonClose }}
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-snackbar>
  </v-container>
</template>
<script>
import { get } from "vuex-pathify";
import { mapActions } from "vuex";
import { json2xlsx, xlsx2json } from "@/util/helpers";


export default {
name: "KPITableCard",
components: {
  ItemTable: () => import("@/components/reference/ReferenceTable.vue"),
  KPIChart: () => import("@/components/chart/KPIChart.vue")
},
props: {
  tag: {
    type: Object
  }
},
data() {
  return {
    loading: false,
    buttonToggle: null,
    search: "",
    selectedYear: 0,
    selectedMonth: 0,
    selectedOrganization: {
      value: 0,
      bin: "000000000000",
      text: "Все организации"
    },
    defaultOrganization: {
      value: 0,
      bin: "000000000000",
      text: "Все организации"
    },
    itemsPerPage: 12,
    months: [
      { text: "Январь", value: 1 },
      { text: "Февраль", value: 2 },
      { text: "Март", value: 3 },
      { text: "Апрель", value: 4 },
      { text: "Май", value: 5 },
      { text: "Июнь", value: 6 },
      { text: "Июль", value: 7 },
      { text: "Август", value: 8 },
      { text: "Сентябрь", value: 9 },
      { text: "Октябрь", value: 10 },
      { text: "Ноябрь", value: 11 },
      { text: "Декабрь", value: 12 }
    ],
    selectXlsx: null,
    plan: {
      id: 0,
      name: "Все организации",
      bin: "000000000000",
      year: new Date().Year,
      indicators: []
    },
    defaultPlan: {
      id: 0,
      name: "Все организации",
      bin: "000000000000",
      year: new Date().Year,
      indicators: []
    },
    snackbar: false,
    snackfaq: false,
    snackload: false,
    snackbarMessage: "",  
    upload: {
      errorMessage: "План не загружен",
      buttonClose: "Закрыть",
      status: "Успешно"
      },
    buttonClose: true,
    response: {
        status: "",
        text: "",
        action: "",
        data: ""
      },
      enrich: {
          errorMessage: "",
          buttonClose: "Закрыть",
          status: "",
      },
      faq: {
          title: "Внимание",
          text: "Для загрузки плана приложите файл с разрешением XSLX (Таблица Excel).\nНеобходимо, чтобы лист с данными назывался 'Лист1'.\nДанные должны быть вписаны построчно (одна организация - одна строка).\n\nФормат ячеек: #столбца-содержимое-(тип ячейки)\n 1-БИН-(текст), 2-Год-(числовой), [3-14]-Ежемесячные показатели-(числовой).\n\nСтроки с пустым БИН, а также с уже имеющимися в базе БИН за указанный год будут пропущены.\n\nДля внесения изменений в плановые показатели, свяжитесь с технической поддержкой (контакты на публичной странице).",
          buttonClose: "Закрыть",
          timeDuration: -1,
      },
  };
},
computed: {
  options: get("report/optionsKPI"),
  years: () => {
    const currentYear = new Date().getFullYear();
    return Array(3)
      .fill(null)
      .map((_, index) => ({ text: currentYear - index, value: index }));
  }
},
methods: {
  ...mapActions({
    uploadPlans: "report/uploadPlans",
    fetchKPIPlans: "report/fetchKPIPlans"
  }),
  enrichIndicators() {
      if (
          this.options.data &&
          this.options.data[this.tag.value] &&
          this.options.data[this.tag.value].indicators.length > 0
      ) {
          return this.options.data[this.tag.value].indicators.map((indicator, index) => ({
              month: this.months[index].text,
              plan: indicator.plan.toFixed(2),
              fact: indicator.fact,
              percent: ((indicator.fact / indicator.plan) * 100).toFixed(2),
              deviation: ((indicator.fact / indicator.plan) * 100 - 100).toFixed(2),
              weightDeviation: (indicator.fact - indicator.plan).toFixed(2)
          }));
      } else {
          this.enrich.errorMessage = "Выбран неверный тип операции. Попробуйте ещё раз.";
          this.enrich.status = "Не удалось выполнить сравнение";
          this.snackbar = true;
          return [];
      }
  },
  setPlan() {
    const indicators = this.enrichIndicators();
    const newPlan =
      this.options.data && this.options.data[this.tag.value]
        ? {
            id: this.options.data[this.tag.value].id,
            name: this.options.data[this.tag.value].name,
            bin: this.options.data[this.tag.value].bin,
            year: this.options.data[this.tag.value].year,
            indicators
          }
        : this.defaultPlan;
    this.plan = newPlan;
  },
  async initialize() {
    this.selectedOrganization = this.defaultOrganization;
    this.selectedMonth = this.months[0];
    this.selectedYear = this.years[0];
    const bin = localStorage.getItem("bin");
    const params = {
      type: this.tag.value,
      bin: bin,
      year: this.selectedYear.text
    };
    await this.fetchKPIPlans(params);
    this.setPlan();
  },
  exportTableToXLSX() {
    this.plan.indicators.length > 0
      ? json2xlsx({
          data: this.plan.indicators,
          header: [this.options.headers.map(header => header.text)],
          sheetName: "Отчет",
          fileName:
            "ИСУНГ - " +
            this.tag.text +
            " - " +
            this.selectedYear.text +
            " - " +
            this.title +
            ".xlsx"
        })
      : console.log(this.errorMessage);
  },
  async fetchPlan() {
    this.loading = true;
    const params = {
      type: this.tag.value,
      bin: this.selectedOrganization.bin,
      year: this.selectedYear.text
    };
    await this.fetchKPIPlans(params);
    this.setPlan();
    this.loading = false;
  },
  uploadXlsx(e) {
    if (!this.selectXlsx) {
        this.upload.errorMessage = "Файл не выбран. Пожалуйста, выберите XLSX-файл."
        this.upload.status = "Внимание"
       this.snackload = true;
    console.log("Необходимо выбрать файл в формте XLSX");
    return;
    }
    if (this.selectXlsx) {
      const reader = new FileReader();
      reader.onload = e => {
        const data = new Uint8Array(e.target.result);
          const result = xlsx2json(data);
          if (result["Лист1"] && result["Лист1"].length > 0) {
              const result2PlanArray = result["Лист1"].map(row => ({
                  Bin: row[0],
                  Year: row[1],
                  M1: row[2],
                  M2: row[3],
                  M3: row[4],
                  M4: row[5],
                  M5: row[6],
                  M6: row[7],
                  M7: row[8],
                  M8: row[9],
                  M9: row[10],
                  M10: row[11],
                  M11: row[12],
                  M12: row[13]
              }));
              this.uploadPlans({ type: this.tag.value, data: result2PlanArray }).then(response => {
                  console.log(response)
                  if (response.text === "Ok" && response.status === "Ok") {
                      this.upload.errorMessage = response.data;
                      this.upload.status = "Успешно"
                      this.snackload = true;
                  } else if (response.data === "При сохранении плана произошла ошибка") {
                      this.upload.errorMessage = response.data;
                      this.upload.status = "Ошибка"
                      this.snackload = true;
                  } else {
                      this.upload.errorMessage = "Произошла ошибка. Выберите корректный XSLX-файл.";
                      this.snackload = true;
                  }
              });
          } else {
              // В случае отсутствия данных выводим сообщение об ошибке
              this.upload.errorMessage = "Файл не содержит ожидаемых данных.";
              this.upload.status = "Ошибка"
              this.snackload = true;
              console.log("Файл не содержит ожидаемых данных.");
          }
      };

      reader.readAsArrayBuffer(this.selectXlsx);
    }
    this.selectXlsx = null;
    },
    showInstructions() {
        this.faq.title = this.faq.title;
        this.faq.text = this.faq.text;
        this.snackfaq = true;
    },
},
async created() {
  await this.initialize();
},
mounted() {}
};
</script>
<style lang="scss" scoped>
.v-text-field >>> label {
font-size: 1.2em;
color: red;
}
.container_bg {
background-size: cover;
background-repeat: no-repeat;
}
.faq-text {
white-space: pre-wrap;
}
     
</style>
